import React from 'react';
import BasicPageStyles from '../styles/BasicPageStyles';

export default function SuccessPage() {
    return (
        <BasicPageStyles>
            <h1>
             SUCCESS!
            </h1>
        </BasicPageStyles>
    )
}